import React, { useEffect, useState } from 'react';
import shopeApi from '../../../api/shopeeApi';
import { notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import copy from 'copy-to-clipboard';
import lazadaApi from '../../../api/lazadaApi';

function EarnCommission({ setOpenModal, platform, link, productInfo }) {
    let platformUrl;
    switch (platform) {
        case 'shopee':
            platformUrl = 'img/brand-logo/logo-shopee-200.png';
            break;
        case 'lazada':
            platformUrl = 'img/brand-logo/logo-lazada-200.png';
            break;
        case 'tiktok':
            platformUrl = 'img/brand-logo/logo-tiktok-shop-200.png';
            break;
        default:
            platformUrl = '';
            break;
    }

    const [linkAff, setLinkAff] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        api.success({
            message: `Đã sao chép`,
            placement: 'top',
        });
    };

    const onCopy = async () => {
        if (!linkAff) return;
        copy(linkAff);
        openNotification();
    };
    useEffect(() => {
        const getLinkAff = async () => {
            try {
                const { data } = await shopeApi.getLinkAff(link);
                console.log(data);
                const shortLink = data.data.batchCustomLink[0].shortLink;
                setLinkAff(shortLink);
            } catch (error) {
                console.log(error);
                setLinkAff('Tải link không thành công');
            }
        };

        const getLinkAffLazada = async () => {
            const { itemId, skuId, offerId } = productInfo;
            try {
                const { data } = await lazadaApi.getLinkAff(
                    itemId,
                    skuId,
                    offerId
                );
                const linkAffLazada = data.linkAff.shortLink;
                setLinkAff(linkAffLazada);
            } catch (error) {
                setLinkAff('Tải link không thành công');
            }
        };
        if (platform === 'shopee') {
            getLinkAff();
        }
        if (platform === 'lazada') {
            getLinkAffLazada();
        }
    }, [link, platform, productInfo]);

    return (
        <div className="absolute bg-white w-full bottom-0 top-8 rounded-t-3xl animate-slideUp">
            {contextHolder}
            <div className="flex flex-col justify-between h-full w-full">
                <div>
                    <div>
                        <div className="flex items-center justify-center p-2">
                            <div>
                                <img
                                    src={platformUrl}
                                    alt="platform-logo"
                                    className="h-10 object-cover"
                                />
                            </div>
                            <h1 className="font-bold">THƯỞNG THÊM HOÀN TIỀN</h1>
                        </div>
                        <div>
                            <p className="text-center">
                                Share link khắp nơi, hoàn tiền đến chẳng ngơi
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center gap-4 py-2 px-4">
                        <span className="underline">{linkAff}</span>
                        <button
                            onClick={onCopy}
                            className="px-2 py-1 bg-primary-600 text-white rounded-md"
                        >
                            Copy
                        </button>
                    </div>
                    <div className="p-4">
                        <h3 className="text-sm font-bold">CƠ CHẾ HOẠT ĐỘNG</h3>
                        <div className="rounded-xl border">
                            <div className="flex items-center border-b last:border-none">
                                <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <p className="text-xs">
                                    Tính năng thưởng thêm hoàn tiền sẽ giúp bạn
                                    gia tăng thu nhập bằng việc chia sẻ đường
                                    link này cho bạn bè, người thân đặt hàng.
                                    Qua đó, khi họ đặt hàng từ link bạn chia sẻ,
                                    bạn sẽ nhận được hoàn tiền thưởng.
                                </p>
                            </div>
                            <div className="flex items-center border-b last:border-none">
                                <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <p className="text-xs">
                                    Link sau khi bạn copy và gửi sẽ tồn tại vĩnh
                                    viễn, tức là sau đó miễn bất kì ai bấm vào
                                    link của bạn và mua hàng thì bạn cũng sẽ
                                    được nhận hoàn tiền. Tuy nhiên vẫn nên thay
                                    link 3 – 6 tháng/lần để tránh việc sản
                                    update, bổ sung tính năng mới.
                                </p>
                            </div>
                            <div className="flex items-center border-b last:border-none">
                                <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <p className="text-xs">
                                    Điều kiện và điều khoản hoàn tiền áp dụng
                                    tương tự như khi bạn tự mua hàng.
                                </p>
                            </div>
                            <div className="flex items-center border-b last:border-none">
                                <div className="h-14 aspect-square flex justify-center items-center text-center text-xl">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <p className="text-xs">
                                    Số tiền hoàn có thể thấp hơn thực tế, bằng
                                    ODE hoặc không hiển thị đúng hàng do chính
                                    sách giảm lần lần tải sản.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
                <div className="p-1 flex justify-between gap-2 text-sm">
                    <button
                        onClick={() => setOpenModal(false)}
                        className="flex-1 border px-1 py-2 rounded-lg bg-primary-600 text-white"
                    >
                        Đóng
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EarnCommission;
